th {
  font-size: 18px !important;
}

tr {
  font-size: 17px !important;
}

select {
  font-size: 16px !important;
}
