@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-scroll::-webkit-scrollbar {
  height: 7px !important;
}

.custom-scroll {
  overflow-y: auto;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: black;
}

.custom-font {
  font-family: 'Oswald', sans-serif !important;
  font-family: 'Rubik', sans-serif !important;
}

.card-animation {
  animation-name: slide;
  animation-duration: 1s;
}

@keyframes slide {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
